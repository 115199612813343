<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <b-tbody>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.fiscal_year_bn : details.fiscal_year }}</b-td>
                      <b-th style="width: 15%" class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</b-th>
                      <b-th class="text-center" style="width: 5%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</b-th>
                      <b-td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? details.org_bn : details.org }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_config.office_type') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.office_type_bn : details.office_type }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.office') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.office_bn : details.office }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_tpm.vanue') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.venue_bn : details.venue }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('elearning_venue.total_capacity') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $n(details.total_capacity) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_venue.rent_amount') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $n(details.rent_amount) }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.contact_person_no') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">
                        {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(details.contact_person_no, { useGrouping: false }) }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.address') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.address_bn : details.address }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.remarks') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.remarks_bn : details.remarks }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.start_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ details.start_date | dateFormat }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.end_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ details.end_date | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th colspan="6" class="text-center text-primary">{{$t('elearning_venue.reservation_info')}}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.name') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.name_bn : details.name }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.mobile') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(details.mobile, { useGrouping: false }) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.email') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ details.email }}</b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.address') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? details.reservation_address_bn : details.reservation_address_bn }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('elearning_config.organization') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">
                            <slot v-if="details.reservation_org_id === 999">
                                {{details.reservation_org_name}}
                            </slot>
                            <slot v-else>
                                {{ $i18n.locale === 'bn' ? details.reservation_org_bn : details.reservation_org }}
                            </slot>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.gentFormData()
      this.details = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      details: {
        id: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        org_id: 0
      },
      officeTypeList: [],
      guestHouseList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'details.org_id': function (newValue) {
        this.officeTypeList = this.officeList(newValue)
    },
    'details.office_type_id': function (newValue) {
        this.guestHouseList = this.guestList(newValue)
    }
  },
  methods: {
    gentFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    officeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    }
  }
}
</script>
