// Venue Reservation api
export const venueReservationBaseUrl = '/venue/venue-reservation/'
export const venueReservationList = venueReservationBaseUrl + 'list'
export const venueReservationToggleStatus = venueReservationBaseUrl + 'toggle-status'
export const venueReservationStore = venueReservationBaseUrl + 'store'
export const venueReservationUpdate = venueReservationBaseUrl + 'update'
export const venueReservationShow = venueReservationBaseUrl + 'show'
export const venueReservationFinalSave = venueReservationBaseUrl + 'final-save'
export const venueReservationSendNothi = venueReservationBaseUrl + 'send-to-nothi'
export const vanueManagementShow = '/config/master-venue-management/show'
// Bill Generates api
export const billGenerateBaseUrl = '/venue/bill-generate/'
export const billGenerateList = billGenerateBaseUrl + 'list'
export const billGenerateToggleStatus = billGenerateBaseUrl + 'toggle-status'
export const billGenerateStore = billGenerateBaseUrl + 'store'
export const billGeneratePayment = '/training-e-learning-service-payment/payment-vanue'
export const billGenerateUpdate = billGenerateBaseUrl + 'update'
export const billGenerateShow = billGenerateBaseUrl + 'show'
export const billGenerateFinalSave = billGenerateBaseUrl + 'final-save'
export const billGeneratePaymentUpdate = '/training-e-learning-service-payment/'

// Nothi api
export const nothi = 'nothi/'
export const pendingNothiVenueList = nothi + 'venue-sent-nothi'
export const reSentNothiVenueList = nothi + 'venue-re-sent-nothi'
